<template>
	<l-modal
		class="m-otp"
		:modal="isLoading"
	>
		<template v-slot:icon>
			<img
				class="m-otp__icon"
				src="@assets/img/lock-icon.svg"
				alt="lock"
			/>
		</template>

		<template v-slot:header>
			{{ $t('OTP_TITLE') }}
		</template>

		<article class="m-otp__content">
			<p class="m-otp__desc text-m-book">
				{{ $t('OTP_DESCRIPTION') }}
			</p>

			<div class="m-otp__container">
				<c-input-otp
					v-model="inputValue"
					:isValid="validations.isValid"
					data-testid="otp-input"
				>
					<c-input-message
						v-if="!validations.isValid"
						:text="otpErrorText"
						type="error"
						slot="message"
						data-testid="otp-error"
					/>
				</c-input-otp>
			</div>
		</article>

		<template v-slot:buttons>
			<c-button
				class="m-otp__button"
				:isLoading="isLoading"
				:disabled="isButtonDisabled"
				data-testid="continue-button"
				@click="onContinue"
			>
				{{ $t('ACTIONS.CONTINUE') }}
			</c-button>
		</template>

		<c-transition
			mode="out-in"
			slot="actions"
		>
			<c-link-text
				v-if="
					isResendCodeEnabled && resendAttempts < totalValidResendAttempts
				"
				class="m-otp__link text-m-medium"
				key="resend-code-link"
				@click.prevent="requestNewCode"
				:href="'#'"
			>
				{{ $t('OTP_RESEND_CODE') }}
			</c-link-text>

			<c-icon
				v-if="!isResendCodeEnabled && isNewCodeRequested"
				class="m-otp__icon"
				src="@icons/check"
				key="check-icon"
			/>
			<c-link-text
				v-if="
					!isNewCodeRequested && resendAttempts >= totalValidResendAttempts
				"
				class="m-otp__link text-m-medium"
				key="help-text"
				@click.prevent="openHelpModal"
			>
				{{ $t('OTP_SHOW_HELP') }}
			</c-link-text>
		</c-transition>
	</l-modal>
</template>

<script>
import { mapState } from 'vuex';
import LModal from '@layouts/l-modal';
import CIcon from '@components/c-icon.vue';
import CButton from '@components/c-cbnk-button.vue';
import CInputOtp from '@components/c-cbnk-input-otp.vue';
import CInputMessage from '@components/c-cbnk-input-message.vue';
import CTransition from '@components/c-transition.vue';
import CLinkText from '@components/c-cbnk-link-text.vue';
import MOtpError from '@modals/m-otp-error.vue';
import MOtpExpired from '@modals/m-otp-expired.vue';
import MOtpInvalid from '@modals/m-otp-invalid.vue';
import MSignBlocked from '@modals/m-cbnk-sign-blocked.vue';
import MSignError from '@modals/m-sign-error.vue';
import MSomethingWrong from '@modals/m-something-wrong.vue';
import MOtpEmail from '@modals/m-cbnk-otp-email.vue';
import {
	OTP_INVALID,
	OTP_EXPIRED,
	OTP_RENEWED,
	OTP_ERROR,
	OTP_LAST_CHANCE,
	OTP_EMAIL_CODE,
} from '@modules/service/constants';

export default {
	name: 'm-cbnk-otp',

	components: {
		LModal,
		CIcon,
		CButton,
		CInputOtp,
		CInputMessage,
		CTransition,
		CLinkText,
	},

	props: {
		processId: String,
		sca: Boolean,
	},

	data() {
		return {
			value: false,
			code: '',
			hasError: false,
			isLoading: false,
			attempts: 0,
			totalValidAttempts: 3,
			resendAttempts: 0,
			totalValidResendAttempts: 2,
			isResendCodeEnabled: false,
			resendCodeTimeout: 0,
			isNewCodeRequested: false,
			isCodeDirty: false,
		};
	},

	computed: {
		...mapState('app', ['isHybridSky']),

		isButtonDisabled({ isLoading, validations }) {
			return (
				validations.isRequired ||
				!validations.hasValidLength ||
				!validations.isValid ||
				isLoading
			);
		},

		inputValue: {
			get() {
				return this.code;
			},

			set(value) {
				this.hasError = false;
				this.isCodeDirty = !this.isNewCodeRequested;
				this.code = value;
			},
		},

		validations({ code, isCodeDirty, hasError }) {
			const otp = String(code);
			const isRequired = !otp?.length;
			const hasValidLength = otp?.length === 6;
			const hasValidChars = !Number.isNaN(+code);

			return {
				isRequired,
				hasValidChars,
				hasValidLength,
				isValid:
					!isCodeDirty || (!isRequired && hasValidChars && !hasError),
			};
		},

		otpErrorText({ validations }) {
			const { isRequired, isValid } = validations;

			if (isRequired) {
				return this.$t('FORM.FIELD.REQUIRED');
			}

			if (!isValid) {
				return this.$t('OTP_ERROR_MESSAGE');
			}

			return '';
		},
	},

	watch: {
		isResendCodeEnabled: {
			immediate: true,
			handler(value) {
				if (!value) {
					clearTimeout(this.resendCodeTimeout);

					this.resendCodeTimeout = setTimeout(() => {
						this.isResendCodeEnabled = true;
					}, 10000);
				}
			},
		},
	},

	methods: {
		onContinue() {
			const { code: otpValue, processId, isLoading, validations } = this;

			if (processId && !isLoading && validations.isValid) {
				this.isLoading = true;
				clearTimeout(this.resendCodeTimeout);

				this.$store
					.dispatch('otp/send', {
						processId,
						otpValue,
					})
					.then((res) => {
						this.value = res;
						this.$emit('close');
					})
					.catch((err) => {
						const errorCode = err?.response?.data?.errorCode;
						const additionalInfo = err?.response?.data?.additionalInfo;

						this.handleErrors(
							{ errorCode, additionalInfo },
							err?.response
						);
					})
					.finally(() => {
						this.isLoading = false;
					});
			}
		},

		async handleErrors({ errorCode, additionalInfo }, response) {
			switch (errorCode) {
				case OTP_INVALID:
					this.handleOtpAttempts();

					if (!additionalInfo) {
						await this.$store.dispatch('modal/open', MOtpError);
						return this.$emit('close');
					}

					break;

				case OTP_LAST_CHANCE:
					this.handleOtpAttempts();

					await this.$store.dispatch('modal/open', MSignError);
					break;

				case OTP_RENEWED:
					this.hasError = true;
					this.attempts += 1;
					break;

				case OTP_EXPIRED:
					const resp = await this.$store.dispatch(
						'modal/open',
						MOtpExpired
					);

					if (resp === 'resend') {
						this.requestNewCode();
					}
					break;

				case OTP_ERROR:
					if (this.sca) {
						const resp = await this.$store.dispatch(
							'modal/open',
							MSignBlocked
						);
						this.value = {
							response: { data: { errorCode: resp } },
						};
					} else {
						await this.openOtpErrorModal();
					}

					this.$emit('close');
					break;
				case OTP_EMAIL_CODE:
					const emailResp = await this.$store.dispatch('modal/open', {
						component: MOtpEmail,
						props: { processId: this.processId },
					});

					this.value = emailResp;

					this.$emit('close');
					break;

				default:
					if (!response?.data) {
						await this.openSomethingWrongModal();
					}

					this.value = { response };

					this.$emit('close');
					break;
			}
		},

		openOtpErrorModal() {
			return this.$store.dispatch('modal/open', MOtpError);
		},

		openSomethingWrongModal() {
			return this.$store.dispatch('modal/open', MSomethingWrong);
		},

		openHelpModal() {
			this.$store.dispatch('modal/open', MOtpInvalid);
		},

		async requestNewCode() {
			const { processId } = this;

			this.code = '';
			this.resendAttempts += 1;
			this.isResendCodeEnabled = false;
			this.isCodeDirty = false;
			this.isNewCodeRequested = true;

			try {
				await this.$store.dispatch('otp/requestCode', processId);
				this.requestOtp();
			} catch {}

			setTimeout(() => {
				this.isNewCodeRequested = false;
			}, 2000);
		},

		requestOtp() {
			if (this.isHybridSky) {
				this.$store.dispatch('otp/requestOtp').then((otp = '') => {
					this.code = otp.replace(/[^0-9]/g, '');
				});
			}
		},

		handleOtpAttempts() {
			this.attempts += 1;
			this.isResendCodeEnabled = true;
			this.hasError = true;
		},
	},

	mounted() {
		this.requestOtp();
	},

	/* istanbul ignore next */
	beforeDestroy() {
		clearTimeout(this.resendCodeTimeout);
	},
};
</script>

<style lang="scss">
.m-otp__content {
	color: RGB(var(--color-text-primary));
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	padding: var(--spacing-s);
}

.m-otp__desc {
	margin-bottom: var(--spacing-xl);
}

.m-otp__link {
	--c-cbnk-link-text-color: RGB(var(--color-text-primary));
}
</style>
