<template>
	<div class="c-input-otp">
		<div class="c-input-content">
			<!-- To cancel browser autofill when input is password -->
			<input
				v-if="!showInputValues"
				autocomplete="off"
				name="hidden"
				type="text"
				hidden
			/>

			<div class="c-input-content__container">
				<ul
					v-if="!showInputValues"
					class="c-input-content__list c-input-content__list--absolute"
					data-testid="masks"
				>
					<li
						class="c-input-content__item c-input-content__item--mask text-l-bold"
						:class="{
							'c-input-content__item--visible': inputValues[index - 1],
						}"
						v-for="index in inputLength"
						:key="`mask-${index}`"
						aria-hidden="true"
					>
						{{ String('*') }}
					</li>
				</ul>

				<div
					class="c-input-content__list"
					data-testid="input-list"
				>
					<input
						v-if="isIOS"
						:value="iOSAutofillCode"
						ref="textfield"
						class="mdc-text-field__input text-m-book"
						autocomplete="one-time-code"
						@input="$emit('model', $event.target.value)"
						v-on="$listeners"
						v-model="iOSAutofillCode"
						:type="'text'"
						hidden
					/>

					<input
						:name="'otp-' + index"
						:id="'otp-' + index"
						:ref="index - 1"
						class="c-input-content__item c-input-content__item--input text-l-medium"
						:class="inputClasses"
						v-for="index in inputLength"
						autocomplete="one-time-code"
						:key="`input-${index}`"
						v-model="inputValues[index - 1]"
						:type="showInputValues ? 'text' : 'password'"
						:aria-label="$t('FORM.FIELD.OTP.ARIA_LABEL', { n: index })"
						inputmode="numeric"
						minlength="0"
						:maxlength="isIOS ? 6 : 1"
						pattern="[0-9]"
						data-testid="input"
						@focus="onFocus($event, index - 1)"
						@blur="activeInput = -1"
						@input="onInput(index)"
						@keydown="onKeydown"
						@paste="onPaste"
					/>
				</div>
			</div>

			<div
				v-if="$slots.message"
				class="c-input-content__container c-input-content__container--message"
				tabindex="0"
			>
				<slot name="message" />
			</div>

			<div
				class="c-input-content__container c-input-content__container--toggle"
			>
				<button
					class="c-input-content__toggle button"
					tabindex="0"
					@click="showInputValues = !showInputValues"
				>
					<c-icon
						v-if="showInputValues"
						class="c-input-content__icon"
						src="@icons/eyeSlash"
						size="xl"
					/>

					<c-icon
						v-else
						class="c-input-content__icon"
						src="@icons/eye"
						size="xl"
					/>

					<span class="c-input-content__text text-l-medium">
						{{
							showInputValues ? $t('INFO.OTP.HIDE') : $t('INFO.OTP.SHOW')
						}}
					</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import CIcon from '@components/c-icon.vue';
import { mapState } from 'vuex';

/* Allowed key codes */
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;

export default {
	name: 'c-cbnk-input-otp',

	components: { CIcon },

	model: {
		prop: 'value',
		event: 'update:value',
	},

	props: {
		value: { type: [String, Number] },
		isValid: { type: Boolean },
		inputLength: {
			type: Number,
			default: 6,
		},
	},

	data() {
		return {
			inputValues: [],
			showInputValues: true,
			activeInput: 0,
			iOSAutofillCode: '',
		};
	},

	computed: {
		inputClasses({ isValid }) {
			return {
				'c-input-content__item--is-invalid': !isValid,
			};
		},
		...mapState('app', ['isIOS']),
	},

	watch: {
		value: {
			handler(nextValue, prevValue) {
				if (nextValue !== prevValue) {
					const inputValues = nextValue.split('');

					this.$set(this, 'inputValues', inputValues);
					this.onInput(inputValues.length);
				}
			},
		},

		activeInput: {
			handler(index) {
				if (this.$refs[index]) {
					this.$refs[index].focus();
				}
			},
		},

		inputValues: {
			deep: true,
			handler(inputValues) {
				this.$emit('update:value', inputValues.join(''));
			},
		},
	},

	methods: {
		onFocus(event, index) {
			this.activeInput = index;

			// We select the content after focus a non-empty target,
			// to allow users change the value easily
			if (event.target.value) {
				event.target.select();
			}
		},

		onInput(index) {
			this.activeInput = Math.max(Math.min(this.inputLength - 1, index), 0);
		},

		focusNextInput() {
			this.onInput(this.activeInput + 1);
		},

		focusPrevInput() {
			this.onInput(this.activeInput - 1);
		},

		changeCodeAtFocus(value) {
			this.$set(this.inputValues, this.activeInput, value);
		},

		onKeydown(event) {
			switch (event.keyCode) {
				case BACKSPACE:
					event.preventDefault();
					this.changeCodeAtFocus('');
					this.focusPrevInput();
					break;
				case DELETE:
					event.preventDefault();
					this.changeCodeAtFocus('');
					break;
				case LEFT_ARROW:
					event.preventDefault();
					this.focusPrevInput();
					break;
				case RIGHT_ARROW:
					event.preventDefault();
					this.focusNextInput();
					break;
				default:
					break;
			}
		},

		onPaste(event) {
			event.preventDefault();
			const clipboardData = event?.clipboardData;

			if (clipboardData && clipboardData.getData) {
				const textData = clipboardData
					.getData('text')
					.replace(/[^0-9]/g, '')
					.split('');
				const currentValues = this.inputValues.slice(0, this.activeInput);
				const combinedValues = currentValues
					.concat(textData)
					.slice(0, this.inputLength);

				this.$set(this, 'inputValues', combinedValues);
				this.onInput(combinedValues.length);
			}
		},
	},

	mounted() {
		if (!this.isIOS) {
			this.showInputValues = false;
		}
		this.$nextTick(() => {
			// We focus the first input as default
			if (this.$refs[this.activeInput]) {
				this.$refs[this.activeInput].focus();
			}
		});
	},
};
</script>

<style lang="scss" scoped>
.c-input-otp,
.c-input-content,
.c-input-content__container {
	display: flex;
	position: relative;
	width: 100%;
}

.c-input-otp {
	justify-content: center;
}

.c-input-content {
	flex-direction: column;
	align-items: center;
	gap: var(--spacing-s);
}

.c-input-content__container {
	max-width: 300px;
	justify-content: center;
}

.c-input-content__container--message,
.c-input-content__container--toggle {
	justify-content: flex-end;
}

.c-input-content__list {
	display: flex;
	position: relative;
	justify-content: center;
	gap: var(--spacing-m);
}

.c-input-content__list--absolute {
	position: absolute;
}

.c-input-content__item {
	color: RGB(var(--color-text-primary));
	visibility: hidden;
	display: inline-flex;
	position: relative;
	width: 40px;
	height: 48px;
	border-radius: var(--border-radius-s);
	background: transparent;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.c-input-content__item--mask {
	font-family: monospace;
}

.c-input-content__item--input {
	visibility: visible;
	caret-color: var(--color-text-primary);
	appearance: none;
	outline: none;
	border: 0.0625rem solid RGB(var(--color-text-primary));
}

.c-input-content__item--visible {
	visibility: visible;
}

.c-input-content__item[type='password'] {
	color: transparent;
}

.c-input-content__item:focus {
	border: 0.0625rem solid RGB(var(--color-secondary-light));
}

.c-input-content__item--is-invalid {
	border: 0.0625rem solid RGB(var(--color-accent-error));
}

.c-input-content__toggle {
	color: RGB(var(--color-text-primary));
	display: flex;
	position: relative;
	gap: var(--spacing-s);
	border-radius: var(--border-radius-s);
}

@media ($s-screen) {
	.c-input-content__container {
		max-width: 348px;
	}

	.c-input-content__item {
		width: 48px;
		height: 56px;
	}
}
</style>
